/* eslint-disable */
import * as React from "react";
import {
  Button,
  Text,
  Authenticator,
  withAuthenticator,
  Card,
  Flex,
  Divider,
  Image,
  Loader,
  Icon,
} from "@aws-amplify/ui-react";
import { uploadData } from "aws-amplify/storage";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import logo from "./logos/Medtronic.jpg";
import ViewFiles from "./ViewFiles";
import { fetchUserAttributes } from "@aws-amplify/auth";

export const App = () => {
  // Initialize the user attributes and upload folder

  const initializeApp = async () => {
    try {
      const userAttributes = await fetchUserAttributes();

      // Set upload folder from custom:uploadFolder attribute
      const folder = userAttributes["custom:uploadFolder"];
      setUploadFolder(folder || "uploaded"); // Default to 'uploaded' if no attribute
      if (process.env.NODE_ENV === "development") {
        console.log("User upload folder:", folder || "uploaded");
      }
    } catch (error) {
      console.error("Failed to fetch user attributes:", error);
    }
  };

  // Call initializeApp on component mount
  React.useEffect(() => {
    initializeApp();
  }, []);

  // Create a reference to the file input element
  const fileInputRef = React.useRef(null);

  // State to manage current application page (upload or view-files)
  const [uploadPage, setAppPage] = React.useState("upload");

  // State to track the list of files being uploaded and their statuses
  const [filesList, setFilesList] = React.useState([]);

  // States to track success and failure counts
  const [successCount, setSuccessCount] = React.useState(0);
  const [failedCount, setFailedCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);

  // State to track current uploads by page
  const [currentPage, setCurrentPage] = React.useState(0);

  // State to handle the hover effect when dragging files into the drop zone
  const [inDropZone, setInDropZone] = React.useState(false);

  // State to handle the upload folder location
  const [userAttributes, setUserAttributes] = React.useState({});
  const [uploadFolder, setUploadFolder] = React.useState("uploaded"); // Default folder

  // Constants for pagination and concurrent uploads
  const FILES_PER_PAGE = 50; // Number of files displayed per page
  const MAX_CONCURRENT_UPLOADS = 10; // Limit concurrent uploads to 10

  // Function to handle files being dropped into the drag-and-drop area
  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    displaySelectedFiles(files);
  };

  // Function to show the hover effect when files are dragged over the drop zone
  const handleDragOver = (event) => {
    event.preventDefault();
    setInDropZone(true); // Show hover effect
  };

  // Function to remove the hover effect when the user drags files out of the drop zone
  const handleDragLeave = () => {
    setInDropZone(false); // Remove hover effect
  };

  // Handle file/folder selection
  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    displaySelectedFiles(files);
  };

  // Function to simulate a click on the hidden file input when "Browse Files" is clicked
  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger file input click
    }
  };

  // Function to prepare files for display and upload
  const displaySelectedFiles = (selectedFiles) => {
    setTotalCount(selectedFiles.length); // Set total files count

    const filesWithStatus = selectedFiles.map((file) => ({
      id: `${file.name}-${file.size}-${file.lastModified}`,
      file,
      progress: 0,
      status: "Waiting...", // Initially set the status to 'Waiting...'
      isSuccess: false, // Initially set success to false
    }));

    // Display files in a list
    setFilesList(filesWithStatus);

    // Process file uploads in batches
    uploadBatch(filesWithStatus);
  };

  // Function to upload files in batches
  let activeUploads = 0;

  const uploadBatch = async (batch) => {
    const queue = [...batch]; // Create a queue from the batch

    // Update initial status to "Pending" for all files
    queue.forEach((fileItem) => {
      updateFileStatus(fileItem.file.name, "Pending", false);
    });

    const uploadNext = async () => {
      // Exit if queue is empty
      if (queue.length === 0) return;

      // Check if we can start a new upload based on the limit
      if (activeUploads < MAX_CONCURRENT_UPLOADS) {
        const fileItem = queue.shift(); // Get the next file in the queue
        activeUploads++; // Increment active uploads counter

        // Start uploading the file
        updateFileStatus(fileItem.file.name, "Uploading...", false);

        uploadFileToS3(fileItem.file)
          .then(() => {
            updateFileStatus(fileItem.file.name, "Uploaded Successfully", true);
          })
          .catch((error) => {
            console.error(`Upload failed for ${fileItem.file.name}:`, error);
            updateFileStatus(
              fileItem.file.name,
              `Upload Failed: ${error.message}`,
              false
            );
          })
          .finally(() => {
            activeUploads--; // Decrement active uploads counter
            uploadNext(); // Trigger the next upload
          });
      }

      // Call uploadNext again to keep the queue moving
      if (activeUploads < MAX_CONCURRENT_UPLOADS && queue.length > 0) {
        setTimeout(uploadNext, 50); // Small delay to allow for the next batch to start
      }
    };

    // Start the initial uploads up to the concurrent limit
    for (let i = 0; i < MAX_CONCURRENT_UPLOADS && queue.length > 0; i++) {
      uploadNext();
    }
  };

  // Function to upload files to the uploaded folder in the bucket
  const uploadFileToS3 = async (file) => {
    let path = `${uploadFolder}/${file.webkitRelativePath || file.name}`; // Use path for folders otherwise use file name

    try {
      // Upload the file to S3 and track its progress
      const operation = uploadData({
        path: path.replace(/\s+/g, "_"), // Clean up spaces in file names
        data: file,
        options: {
          useAccelerateEndpoint: file.size > 100 * 1024 * 1024, // Enable acceleration for files > 100 MB
          onProgress: ({ transferredBytes, totalBytes }) => {
            // Update progress for each file
            const progress = Math.round((transferredBytes / totalBytes) * 100);
            updateFileProgress(file.name, progress);
          },
        },
      });

      const result = await operation.result;

      // Increment success count immediately after success
      setSuccessCount((prev) => prev + 1);

      // Log only in development environment
      if (process.env.NODE_ENV === "development") {
        console.log("File uploaded successfully:", result);
      }
      updateFileStatus(file.name, "Uploaded Successfully", true); // Mark as success
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error("Error uploading file:", error);
      }

      // Increment failed count if upload fails
      setFailedCount((prev) => prev + 1);

      updateFileStatus(file.name, `Upload Failed: ${error.message}`, false); // Mark file as failed
    }
  };

  // Function to update file progress in UI
  const updateFileProgress = (fileName, progress) => {
    setFilesList((prevList) =>
      prevList.map((f) => (f.file.name === fileName ? { ...f, progress } : f))
    );
  };

  // Function to update file status in UI
  const updateFileStatus = (fileName, status, isSuccess) => {
    setFilesList((prevList) =>
      prevList.map((f) =>
        f.file.name === fileName ? { ...f, status, isSuccess } : f
      )
    );
  };

  // Pagination functions
  const paginatedFilesList = () => {
    const start = currentPage * FILES_PER_PAGE;
    return filesList.slice(start, start + FILES_PER_PAGE);
  };

  const nextPage = () => {
    if ((currentPage + 1) * FILES_PER_PAGE < filesList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to clear the file list
  const handleClearFiles = () => {
    setFilesList([]); // Clear the list of uploaded files
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input selection
    }
    setSuccessCount(0); // Reset success count
    setFailedCount(0); // Reset failed count
    setTotalCount(0); // Reset total count
  };

  return (
    <Authenticator>
      {({ signOut }) => (
        <div className="AppAuth">
          {/* App Header section */}
          <header className="App-header">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Medtronic Logo */}
              <img src={logo} alt="App Logo" className="app-logo" />

              {/* Sign Out button */}
              <Button onClick={signOut} className="sign-out-button">
                Sign Out
              </Button>
            </div>
          </header>

          {/* Conditional Rendering for Upload Page or View Files Page */}
          {uploadPage === "upload" ? (
            <>
              {/* Upload Page */}
              <Text className="upload-instruction">
                Please upload your files below.
              </Text>

              {/* Browse Files and drag and drop file selection*/}
              <div
                className={`upload-container ${
                  inDropZone ? "dragging-over" : ""
                }`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <input
                  type="file"
                  directory=""
                  webkitdirectory="" // Allow folder selection
                  multiple
                  onChange={handleFileSelection}
                  ref={fileInputRef} // Reference to input for triggering via browse button
                  className="file-input"
                />
                <p>Drag and drop your files here</p>

                {/* Browse files button */}
                <Button className="browse-button" onClick={handleBrowseClick}>
                  Upload Folders
                </Button>
              </div>

              {/* Display success and failure counts */}
              <div className="file-counts">
                <Text>
                  Uploaded Successfully: {successCount} / {totalCount}
                </Text>
                <Text>Upload Failed: {failedCount}</Text>
              </div>

              {/* Pagination controls */}
              <div className="pagination">
                <Button onClick={previousPage} disabled={currentPage === 0}>
                  Previous
                </Button>
                <Button
                  onClick={nextPage}
                  disabled={
                    (currentPage + 1) * FILES_PER_PAGE >= filesList.length
                  }
                >
                  Next
                </Button>
              </div>

              {/* Display Clear File List button */}
              <Flex justifyContent="flex-start" padding="20px">
                <Button
                  onClick={handleClearFiles}
                  className="clear-files-button"
                >
                  Clear File List
                </Button>

                {/* Conditional View Files / Go Back Button */}
                <Button
                  onClick={() =>
                    setAppPage(
                      uploadPage === "upload" ? "view-files" : "upload"
                    )
                  }
                  className="view-files-button"
                  style={{ marginLeft: "10px" }}
                >
                  {uploadPage === "upload" ? "View Files" : "Go Back"}
                </Button>
              </Flex>

              {/* Display file list and upload progress */}
              <div>
                {paginatedFilesList().map((fileItem) => (
                  <Card key={fileItem.id} variation="elevated">
                    <Flex direction="row" alignItems="center">
                      <Text>{fileItem.file.name}</Text>

                      {/* Show progress of files being uploaded */}
                      {fileItem.progress < 100 && !fileItem.isSuccess ? (
                        <Loader
                          percentage={fileItem.progress || 0}
                          size="small"
                        />
                      ) : (
                        <Icon
                          name="check"
                          fontSize="large"
                          color="green"
                          style={{ marginLeft: "10px" }}
                        />
                      )}

                      {/* Display success or waiting of each file uploaded */}
                      <Text>{fileItem.status || "Waiting..."}</Text>
                    </Flex>
                  </Card>
                ))}
              </div>
            </>
          ) : (
            <ViewFiles goToHome={() => setAppPage("upload")} />
          )}
        </div>
      )}
    </Authenticator>
  );
};

export default withAuthenticator(App);
