/* eslint-disable */
import React, { useState, useEffect } from "react";
import { fetchUserAttributes } from "@aws-amplify/auth"; // For user attributes
import { list, getUrl } from "@aws-amplify/storage"; // For listing files and generating signed URLs for download
import {
  Button,
  Loader,
  Text,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import "./ViewFiles.css";

const ViewFiles = ({ goToHome }) => {
  //const folder = "essentia/"; // Hardcoded folder for testing
  const [files, setFiles] = useState([]); // Holds the list of files/folders
  const [loading, setLoading] = useState(false); // Indicates loading state
  const [downloading, setDownloading] = useState(false); // Indicates downloading state
  const [error, setError] = useState(null); // Store error messages
  const [userEmail, setUserEmail] = useState(""); // User email for display
  const [folderAccess, setFolderAccess] = useState(""); // User folder access attribute
  const [selectedFiles, setSelectedFiles] = useState([]); // Track selected files for download
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME; // Bucket name specified in the env file
  const API_NAME = process.env.REACT_APP_API_NAME; // Api name specified in the env file

  if (process.env.NODE_ENV === "development") {
    if (!BUCKET_NAME || !API_NAME) {
      console.error("Environment variables are missing. Check .env file.");
    }
    console.log("Loaded Environment Variables:", {
      BUCKET_NAME,
      API_NAME,
      NODE_ENV: process.env.NODE_ENV,
    });
  }

  useEffect(() => {
    // Initialize the component by fetching user attributes and listing files
    const initialize = async () => {
      try {
        setLoading(true);

        // Fetch Cognito user attributes
        const userAttributes = await fetchUserAttributes();
        if (process.env.NODE_ENV === "development") {
          console.log("User Attributes:", userAttributes);
        }

        // Set user email and folder access attribute
        setUserEmail(userAttributes.email || "Unknown");
        setFolderAccess(userAttributes["custom:uploadFolder"] || "None");

        // Fetch files in the folder specified by the custom attribute folderAccess
        fetchFiles(userAttributes["custom:uploadFolder"]);
      } catch (err) {
        console.error("Error initializing view:", err);
        setError("Failed to initialize. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []);

  // Fetch files for the given folder
  const fetchFiles = async (folderPath) => {
    try {
      setLoading(true);
      setError(null);

      if (process.env.NODE_ENV === "development") {
        console.log("Fetching files from folder:", folderPath);
      }

      // List files and folders within the specified folderPath
      const response = await list({
        path: folderPath,
        options: {
          //bucket: BUCKET_NAME,
          //region: "us-east-1",
          listAll: true,
        },
      });

      // Filter out the folder path itself (the folder we're viewing), but keep subfolders and files
      if (response.items && response.items.length > 0) {
        const filteredFiles = response.items.filter((item) => {
          // Only exclude the folder path
          return item.path !== `${folderPath}/`;
        });
        setFiles(filteredFiles); // Use the filtered list
      } else {
        setFiles([]);
      }

      if (process.env.NODE_ENV === "development") {
        console.log("Files fetched:", response.items);
      }
    } catch (err) {
      console.error("Error fetching files:", err);
      setError("Failed to load files. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handles selecting or deselecting a file for download
  const handleFileSelect = (filePath) => {
    setSelectedFiles(
      (prevSelected) =>
        prevSelected.includes(filePath)
          ? prevSelected.filter((path) => path !== filePath) // Deselect
          : [...prevSelected, filePath] // Add to selection
    );
  };

  // Handles downloading of the selected files
  const handleDownloadSelected = async () => {
    if (selectedFiles.length === 0) {
      setError("Please select at least one file to download.");
      return;
    }

    setDownloading(true);

    try {
      if (selectedFiles.length === 1) {
        const filePath = selectedFiles[0];
        const { url } = await getUrl({
          path: filePath,
          options: { expiresIn: 900 }, // 15 Minutes
        });

        fetch(url, { method: "GET" })
          .then((response) => {
            if (!response.ok) throw new Error("Failed to fetch file.");
            return response.blob();
          })
          .then((blob) => {
            if (blob.size === 0) throw new Error("Downloaded file is empty.");
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = filePath.split("/").pop();
            link.click();
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
            setError("Failed to download the file.");
          });
      } else {
        const sanitizedFilePaths = selectedFiles.map((file) =>
          file.startsWith(`${folderAccess}/`)
            ? file.slice(folderAccess.length + 1)
            : file
        );

        const payload = {
          bucketName: BUCKET_NAME,
          folderPath: `${folderAccess}/`,
          filePaths: sanitizedFilePaths,
        };

        if (process.env.NODE_ENV === "development") {
          console.log("Sending payload to Lambda:", payload);
        }

        const response = await fetch(API_NAME, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        if (!response.ok) throw new Error("Failed to generate ZIP file.");

        const lambdaResponse = await response.json();
        if (process.env.NODE_ENV === "development") {
          console.log("Lambda Response:", lambdaResponse);
        }

        // Check if body is stringified JSON or already an object
        const body =
          typeof lambdaResponse.body === "string"
            ? JSON.parse(lambdaResponse.body) // Parse if stringified
            : lambdaResponse.body;

        const zipUrl = body.zipUrl;
        if (process.env.NODE_ENV === "development") {
          console.log("Extracted Zip URL:", zipUrl);
        }

        fetch(zipUrl, { method: "GET" })
          .then((response) => {
            if (!response.ok) throw new Error("Failed to fetch ZIP file.");
            return response.blob();
          })
          .then((blob) => {
            if (blob.size === 0) throw new Error("Downloaded ZIP is empty.");
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "downloaded-files.zip";
            link.click();
          })
          .catch((error) => {
            console.error("Error downloading ZIP file:", error);
            setError("Failed to download the ZIP file.");
          });
      }
    } catch (err) {
      console.error("Error:", err);
      setError("Failed to download selected files.");
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className="view-files-header">
      {/* Display the Object count in the current folder */}
      <h1>
        Objects <span>({files.length})</span>
      </h1>

      {/* Display Cognito user attributes */}
      <div className="attributes-container">
        <Text>User Email: {userEmail}</Text>
        <Text>Folder Access: {folderAccess}</Text>
      </div>

      {loading && <Loader />}
      {error && <Text style={{ color: "red" }}>{error}</Text>}

      {/* Go Back button */}
      <Button
        onClick={goToHome}
        className="go-back-button"
        style={{ marginBottom: "20px" }}
      >
        Go Back
      </Button>

      {/* Display error message if No files found */}
      {!loading && !error && files.length === 0 && (
        <Text>No files found in the folder.</Text>
      )}

      {downloading && (
        <div className="spinner-container">
          <div className="spinner"></div>
          <p>Preparing your download...</p>
        </div>
      )}

      {/* Table Buttons */}
      <div className="table-container-button">
        {/* Download Button */}
        {files.length > 0 && (
          <Button
            onClick={handleDownloadSelected}
            className="download-button"
            style={{ marginBottom: "20px" }}
          >
            Download
          </Button>
        )}

        {/* Refresh Button */}
        <Button
          onClick={() => fetchFiles(folderAccess)} // Call the function to refresh files
          className="refresh-button"
        >
          <img
            src={require("./logos/refresh.svg").default}
            alt="Refresh"
            style={{ width: "20px", height: "20px" }}
          />
        </Button>
      </div>

      {/* Display files in a table */}
      <div className="table-container">
        <Table className="custom-table">
          {/* Table column header for Name, Type, Last Modified, and Size */}
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Last Modified</TableCell>
              <TableCell>Size</TableCell>
            </TableRow>
          </TableHead>

          {/* Table body to display the files */}
          <TableBody>
            {files.length > 0 ? (
              files.map((file) => (
                // Map over the `files` array to dynamically create a table row for each file or folder
                <TableRow
                  key={file.path}
                  className={
                    selectedFiles.includes(file.path) ? "selected-row" : ""
                  } // Apply CSS to highlight the row if the file is selected
                >
                  <TableCell>
                    <input
                      type="checkbox"
                      disabled={file.path.includes(".") ? false : true} // Disable checkbox if type is Folder
                      checked={selectedFiles.includes(file.path)} // Check if the file is selected
                      onChange={() => handleFileSelect(file.path)} // Toggle selection
                    />
                  </TableCell>
                  <TableCell>
                    {file.path.replace(folderAccess, "").replace(/^\/+/, "")}
                  </TableCell>
                  <TableCell>
                    {file.path.includes(".")
                      ? file.path.split(".").pop().toLowerCase()
                      : "Folder"}
                  </TableCell>
                  <TableCell>
                    {file.lastModified
                      ? new Date(file.lastModified).toLocaleString()
                      : "Unknown"}
                  </TableCell>
                  <TableCell>
                    {file.size
                      ? file.size < 1024 * 1024
                        ? `${(file.size / 1024).toFixed(1)} KB`
                        : `${(file.size / (1024 * 1024)).toFixed(1)} MB`
                      : "-"}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} style={{ textAlign: "center" }}>
                  No files found in this folder.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ViewFiles;
